<template>
  <div class="case">
    <div class="case_banner_wraper">
      <div class="case_banner_wraper_item">
        <div class="case_banner_wraper_item_title">政务办公模式就此革新</div>
        <div class="case_banner_wraper_item_desc">
          开启政府单位的智能化办公时代,工作其实可以更轻松、简单、高效
        </div>
        <div class="case_banner_wraper_item_btn" @click="chat">在线咨询</div>
      </div>
    </div>
    <div class="case_intro">
      <div class="case_intro_title">案例介绍</div>
      <div class="case_intro_wraper">
        <div class="case_intro_wraper_left">
          <img src="@/assets/images/case/intro.png" alt="" />
        </div>
        <div class="case_intro_wraper_right">
          <div class="case_intro_wraper_right_title">
            佛山市高明区 <span>（各级200+单位全覆盖）</span>
          </div>
          <div class="case_intro_wraper_right_desc">
            助高明区建成全国首个全覆盖的智能政务系统
          </div>
          <div class="case_intro_wraper_right_num">
            <span>用户数：9314个</span><span>办件数：411025件/年</span>
          </div>
          <div class="case_intro_wraper_right_detail">
            实现党政机关、群众、企业全链接。区有关机关、区委工作部门、政府工作部门、<br />
            各镇政府、街道办事处、村居、其他事业单位、学校、医院等共同使用同一办公系统。<br />
            >全区200多个单位实现智慧办公<br />
            >实现各级单位横向、纵向全覆盖一网式办公<br />
            >办文、办会、办事、督办等多功能一体化智慧办公<br />
          </div>
        </div>
      </div>
      <div class="case_intro_item">
        <div
          class="case_intro_item_li"
          v-for="item in item"
          :key="item.id"
          :class="
            item.id % 2 === 0 ? 'case_intro_item_odd' : 'case_intro_item_even'
          "
        >
          <img :src="item.img" alt="" />
          <div class="case_intro_item_li_title">{{ item.name }}</div>
        </div>
      </div>
      <div class="case_intro_tip">
        成效报道
        <img src="@/assets/images/case/tip.png" alt="" />
      </div>
      <div class="case_intro_list">
        <div
          class="case_intro_list_li"
          v-for="(item, index) in list"
          :key="index"
        >
          <img :src="item.img" alt="" />
        </div>
      </div>
    </div>
    <div class="case_county_wraper">
      <div class="case_county">
        <div class="case_intro_wraper">
          <div class="case_intro_wraper_right">
            <div class="case_intro_wraper_right_title">
              延安市富县 <span>（各级200+单位全覆盖）</span>
            </div>
            <div class="case_intro_wraper_right_desc">
              两大系统，开启政府办公智慧化时代
            </div>
            <div class="case_intro_wraper_right_num">
              <span>用户数：9314个</span><span>办件数：411025件/年</span>
            </div>
            <div class="case_intro_wraper_right_detail">
              县委工作部门、县政府工作部门、县有关机关各镇政府、<br />
              街道办事处、村居、其他事业单位使用同一办公系统。<br />
              >全县200多个单位实现智慧办公<br />
              >建设效能型政府，为基层人员减负增效<br />
              >打造服务型政府，为群众提供便捷办事渠道
            </div>
          </div>
          <div class="case_intro_wraper_left">
            <img src="@/assets/images/case/intro.png" alt="" />
          </div>
        </div>
        <div class="case_county_item">
          <div class="case_county_li" v-for="item in itemList" :key="item.id">
            <img :src="item.img" alt="" />
            <div class="case_county_li_title">{{ item.name }}</div>
          </div>
          <div class="case_county_last">
            <img src="@/assets/images/case/icon8.png" alt="" />
            <div class="case_county_li_title">互联网+ <br />政务服务平台</div>
          </div>
        </div>
        <div class="case_intro_tip">
          成效报道
          <img src="@/assets/images/case/tip.png" alt="" />
        </div>
        <div class="case_intro_listIcon">
          <div
            class="case_intro_list_li"
            v-for="(item, index) in listImg"
            :key="index"
          >
            <img :src="item.img" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="case_city_wraper">
      <div class="case_city">
        <div class="case_intro_wraper">
          <div class="case_intro_wraper_left">
            <img src="@/assets/images/case/list9.png" alt="" />
            <img src="@/assets/images/case/list10.png" alt="" />
          </div>
          <div class="case_intro_wraper_right">
            <div class="case_intro_wraper_right_title">
              佛山市三水区 <span>（各级200+单位全覆盖）</span>
            </div>
            <div class="case_intro_wraper_right_desc">
              助三水区大幅提升政务办公效能
            </div>
            <div class="case_intro_wraper_right_num">
              <span>用户数：7896个</span><span>办件数：422120件/年</span>
            </div>
            <div class="case_intro_wraper_right_detail">
              区有关机关、区委工作部门、政府工作部门、各镇政府街道办事处、<br />
              村居、其他事业单位共同使用同一办公系统。其中三水区水务局、经促局、<br />
              经科局、南山镇、西南街道、医保局、区委区政府都使用了电子会务系统<br />
              >全区各级200多个单位实现智慧办公<br />
              >多个单位实现无纸化办会<br />
              >全面提升机关单位行政效能<br />
            </div>
          </div>
        </div>
        <div class="case_intro_item">
          <div
            class="case_intro_item_li"
            v-for="item in itemIcon"
            :key="item.id"
            :class="
              item.id % 2 === 0 ? 'case_intro_item_odd' : 'case_intro_item_even'
            "
          >
            <img :src="item.img" alt="" />
            <div class="case_intro_item_li_title">{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="case_city_last_wraper">
      <div class="case_city_last">
        <div class="case_intro_wraper">
          <div class="case_intro_wraper_right">
            <div class="case_intro_wraper_right_title">
              延安市吴起县 <span>（各级200+单位全覆盖）</span>
            </div>
            <div class="case_intro_wraper_right_desc">打造绿色会议新模式</div>
            <div class="case_intro_wraper_right_num">
              <span>用户数：200个</span>
            </div>
            <div class="case_intro_wraper_right_detail">
              县委工作部门、县政府工作部门、县有关机关、各镇政府、其他事业单位使用电子会务系统<br />
              >全县200多个单位实现会议无纸化<br />
              >有效降低行政成本，提升会议质量
            </div>
            <div class="case_intro_wraper_right_img">
              <img src="@/assets/images/case/icon2.png" alt="" />
              电子会务
            </div>
          </div>
          <div class="case_intro_wraper_left">
            <img src="@/assets/images/case/list11.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  components: {

  },
  props: {

  },
  data () {
    return {
      item: [
        {
          id: 1,
          img: require('@/assets/images/case/icon1.png'),
          name: 'OA系统'
        },
        {
          id: 2,
          img: require('@/assets/images/case/icon2.png'),
          name: '电子会务'
        },
        {
          id: 3,
          img: require('@/assets/images/case/icon3.png'),
          name: '行政导航'
        },
        {
          id: 4,
          img: require('@/assets/images/case/icon4.png'),
          name: '移动办公'
        },
        {
          id: 5,
          img: require('@/assets/images/case/icon5.png'),
          name: '智慧饭堂'
        },
        {
          id: 6,
          img: require('@/assets/images/case/icon6.png'),
          name: '领导日程'
        },
        {
          id: 7,
          img: require('@/assets/images/case/icon7.png'),
          name: '高明通微信综合服务平台'
        }
      ],
      itemIcon: [
        {
          id: 1,
          img: require('@/assets/images/case/icon1.png'),
          name: 'OA系统'
        },
        {
          id: 2,
          img: require('@/assets/images/case/icon2.png'),
          name: '电子会务'
        },
        {
          id: 3,
          img: require('@/assets/images/case/icon9.png'),
          name: '工程项目管理系统'
        },
        {
          id: 4,
          img: require('@/assets/images/case/icon10.png'),
          name: '数据报送系统'
        },
        {
          id: 5,
          img: require('@/assets/images/case/icon5.png'),
          name: '智慧饭堂'
        }
      ],
      list: [
        {
          img: require('@/assets/images/case/list1.png')
        },
        {
          img: require('@/assets/images/case/list2.png')
        },
        {
          img: require('@/assets/images/case/list3.png')
        },
        {
          img: require('@/assets/images/case/list4.png')
        }
      ],
      itemList: [
        {
          id: 1,
          img: require('@/assets/images/case/icon1.png'),
          name: 'OA系统'
        },
        {
          id: 2,
          img: require('@/assets/images/case/icon2.png'),
          name: '移动办公'
        },
        {
          id: 3,
          img: require('@/assets/images/case/icon3.png'),
          name: '电子会务'
        }
      ],
      listImg: [
        {
          img: require('@/assets/images/case/list5.png')
        },
        {
          img: require('@/assets/images/case/list6.png')
        },
        {
          img: require('@/assets/images/case/list7.png')
        },
        {
          img: require('@/assets/images/case/list8.png')
        }
      ]
    }
  },
  computed: {

  },
  watch: {

  },
  created () {

  },
  mounted () {

  },
  methods: {
    chat () {
      this.$chat()
    }
  }
}
</script>

<style scoped lang="scss">
@import './style/index.scss';
</style>
